import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementUI from 'element-ui'
import { Message, MessageBox } from 'element-ui';
// import pagination from "@/components/pagination.vue";

import './assets/css/swiper.min.css' // element-ui css样式
import 'element-ui/lib/theme-chalk/index.css' // element-ui css样式
import './assets/css/reset.css' //项目样式初始化
import './assets/css/common.less'; //通用样式表
// import '@/permission' // 路由守卫

Vue.use(ElementUI)

Vue.config.productionTip = false;
Vue.prototype.$message = Message;
Vue.prototype.$confirm = MessageBox.confirm;

// Vue.component("pagination", pagination);

import VueClipboard from 'vue-clipboard2'
Vue.use(VueClipboard)

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
