import Cookies from 'js-cookie'

const TokenKeyA = 'vue_tokenA'
const TokenKeyB = 'vue_tokenB'
const user = 'vue_user'
const userId = 'vue_userId'
const ChoiceEdu= 'vue_choiceEdu'
const password= 'vue_password'

export function getPassword() {
    return Cookies.get(password)?JSON.parse(Cookies.get(password)):Cookies.get(password)
}
export function setPassword(value) {
    return Cookies.set(password, JSON.stringify(value),{ expires: 365 })
}
export function removePassword() {
    return Cookies.remove(password)
}
export function getTokenA() {
    return Cookies.get(TokenKeyA)
}
export function getTokenB() {
    return Cookies.get(TokenKeyB)
}

export function setTokenA(token) {
    return Cookies.set(TokenKeyA, token,{ expires: 365 })
}
export function setTokenB(token) {
    return Cookies.set(TokenKeyB, token,{ expires: 365 })
}

export function getUser() {
    return Cookies.get(user)?JSON.parse(Cookies.get(user)):''
}
    
export function setUser(use) {
    return Cookies.set(user, JSON.stringify(use),{ expires: 365 })
}

export function getChoiceEdu() {
    return JSON.parse(Cookies.get(ChoiceEdu))
}
export function setChoiceEdu(use) {
    return Cookies.set(ChoiceEdu, JSON.stringify(use),{ expires: 365 })
}

export function getUserId() {
    return Cookies.get(userId)
}
    
export function setUserId(use) {
    return Cookies.set(userId, use,{ expires: 365 })
}

export function removeToken() {
    Cookies.remove(ChoiceEdu)
    Cookies.remove(userId)
    Cookies.remove(user)
    Cookies.remove(TokenKeyB)
    return Cookies.remove(TokenKeyA)
}

export function formatTime(date) {
    var year = date.getFullYear()
    var month = date.getMonth() + 1
    var day = date.getDate()

    var hour = date.getHours()
    var minute = date.getMinutes()
    var second = date.getSeconds()

    return [year, month, day].map(formatNumber).join('-') + ' ' + [hour, minute, second].map(formatNumber).join(':')
}

function formatNumber(n) {
    n = n.toString()
    return n[1] ? n : '0' + n
}

/** 
 * 时间戳转化为年 月 日 时 分 秒 
 * number: 传入时间戳 
 * format：返回格式，支持自定义，但参数必须与formateArr里保持一致 
*/
export function formatTimeTwo(number, format,is=true) {

    var formateArr = ['Y', 'M', 'D', 'h', 'm', 's'];
    var returnArr = [];

    var date = new Date(number);
    returnArr.push(date.getFullYear());
    if(is){
        returnArr.push(formatNumber(date.getMonth() + 1));
        returnArr.push(formatNumber(date.getDate()));

        returnArr.push(formatNumber(date.getHours()));
        returnArr.push(formatNumber(date.getMinutes()));
        returnArr.push(formatNumber(date.getSeconds()));
    }else{
        returnArr.push(date.getMonth() + 1);
        returnArr.push(date.getDate());

        returnArr.push(date.getHours());
        returnArr.push(date.getMinutes());
        returnArr.push(date.getSeconds());
    }
    for (var i in returnArr) {
        format = format.replace(formateArr[i], returnArr[i]);
    }
    return format;
}

export function Base64() {

    // private property
    var _keyStr = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=";

    // public method for encoding
    this.encode = function (input) {
        var output = "";
        var chr1, chr2, chr3, enc1, enc2, enc3, enc4;
        var i = 0;
        input = _utf8_encode(input);
        while (i < input.length) {
            chr1 = input.charCodeAt(i++);
            chr2 = input.charCodeAt(i++);
            chr3 = input.charCodeAt(i++);
            enc1 = chr1 >> 2;
            enc2 = ((chr1 & 3) << 4) | (chr2 >> 4);
            enc3 = ((chr2 & 15) << 2) | (chr3 >> 6);
            enc4 = chr3 & 63;
            if (isNaN(chr2)) {
                enc3 = enc4 = 64;
            } else if (isNaN(chr3)) {
                enc4 = 64;
            }
            output = output +
            _keyStr.charAt(enc1) + _keyStr.charAt(enc2) +
            _keyStr.charAt(enc3) + _keyStr.charAt(enc4);
        }
        return output;
    }

    // public method for decoding
    this.decode = function (input) {
        var output = "";
        var chr1, chr2, chr3;
        var enc1, enc2, enc3, enc4;
        var i = 0;
        input = input.replace(/[^A-Za-z0-9\+\/\=]/g, "");
        while (i < input.length) {
            enc1 = _keyStr.indexOf(input.charAt(i++));
            enc2 = _keyStr.indexOf(input.charAt(i++));
            enc3 = _keyStr.indexOf(input.charAt(i++));
            enc4 = _keyStr.indexOf(input.charAt(i++));
            chr1 = (enc1 << 2) | (enc2 >> 4);
            chr2 = ((enc2 & 15) << 4) | (enc3 >> 2);
            chr3 = ((enc3 & 3) << 6) | enc4;
            output = output + String.fromCharCode(chr1);
            if (enc3 != 64) {
                output = output + String.fromCharCode(chr2);
            }
            if (enc4 != 64) {
                output = output + String.fromCharCode(chr3);
            }
        }
        output = _utf8_decode(output);
        return output;
    }
    // private method for UTF-8 encoding
    var _utf8_encode = function (string) {
        string = string.replace(/\r\n/g,"\n");
        var utftext = "";
        for (var n = 0; n < string.length; n++) {
            var c = string.charCodeAt(n);
            if (c < 128) {
                utftext += String.fromCharCode(c);
            } else if((c > 127) && (c < 2048)) {
                utftext += String.fromCharCode((c >> 6) | 192);
                utftext += String.fromCharCode((c & 63) | 128);
            } else {
                utftext += String.fromCharCode((c >> 12) | 224);
                utftext += String.fromCharCode(((c >> 6) & 63) | 128);
                utftext += String.fromCharCode((c & 63) | 128);
            }
        }
        return utftext;
    }
    // private method for UTF-8 decoding
   var  _utf8_decode = function (utftext) {
        var string = "";
        var i = 0;
        let c =0, c1 =0, c2 = 0;
        while ( i < utftext.length ) {
            c = utftext.charCodeAt(i);
            if (c < 128) {
                string += String.fromCharCode(c);
                i++;
            } else if((c > 191) && (c < 224)) {
                c2 = utftext.charCodeAt(i+1);
                string += String.fromCharCode(((c & 31) << 6) | (c2 & 63));
                i += 2;
            } else {
                c2 = utftext.charCodeAt(i+1);
                c1 = utftext.charCodeAt(i+2);
                string += String.fromCharCode(((c & 15) << 12) | ((c2 & 63) << 6) | (c3 & 63));
                i += 3;
            }
        }
        return string;
    }
}