module.exports = {

  title: 'GO互动平台V3.0',

  /**
   * @type {boolean} true | false
   * @description Whether fix the header
   */
  fixedHeader: true,

  /**
   * @type {boolean} true | false
   * @description Whether show the logo in sidebar
   */
  sidebarLogo: true,

  /**
   * @type {boolean} true | false
   * @description Whether need tagsView
   */
  tagsView: true,
  visitedViews: true,
  uploadUrl: '/api/admin/Indexauth/upload',
  uploadMp4: '/api/admin/Indexauth/uploadMp4',
}
