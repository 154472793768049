import { asyncRoutes, constantRoutes } from '@/router'
/* home */

const state = {

}

const mutations = {

}

/**
 * 后台查询的菜单数据拼装成路由格式的数据
 * @param routes
 * @param data
 */
export function generaMenu(routes, data,commit) {
 
}

const actions = {
  
}

export default {

}
