import request from '@/utils/request'
import { MessageBox } from 'element-ui'
import router from '@/router'
import store from "@/store";

//登录
export function login(data) {
    return request({
        url: 'api/oauth2/token',
        method: 'post',
        headers: { 'Content-Type': 'multipart/form-data' },
        data
    })
}
//注册
export function register(data) {
    return request({
        url: 'api/oauth2/register',
        method: 'post',
        data
    })
}
//找回账号
export function findusername(data) {
    return request({
        url: 'api/oauth2/findusername',
        method: 'get',
        params:data
    })
}
//找回密码
export function findpassword(data) {
    return request({
        url: 'api/oauth2/findpassword',
        method: 'post',
        params:data
    })
}


//退出登录
export function logout(data) {
    return request({
        url: 'api/oauth2/logout',
        method: 'post',
        data
    })
}
//用户信息
export function payAccountcheck(data) {
    return request({
        url: 'api/pay/accountcheck',
        method: 'get',
        // 点击刷新不加载
        headers:{
            showLoading:false  

        },
        data
    })
}
//立即升级列表数据
export function alipayBuytypes(data) {
    return request({
        url: 'api/pay/buytypes',
        method: 'get',
        data
    })
}

//支付
export function alipayPay(data) {
    return request({
        url: 'api/pay/pay',
        method: 'post',
        params:data
    })
}
//会话
export function engineQuery(data) {
    return request({
        url: 'api/engine/query',
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        data
    })
}
//支付确认
export function paycheck(data) {
    return request({
        url: 'api/pay/paycheck',
        method: 'get',
        data
    })
}
//修改密码
export function oauthChangepassword(data) {
    return request({
        url: 'api/oauth2/changepassword',
        method: 'post',
        params:data
    })
}
//获取用户
export function boardGetuser(data) {
    return request({
        url: 'api/board/getuser',
        method: 'get',
        params:data
    })
}
//获取用户日志
export function boardGetinfo(data) {
    return request({
        url: 'api/board/getinfo',
        method: 'get',
        data
    })
}
//风控接触
export function boardDismissban(data) {
    return request({
        url: 'api/board/dismissban',
        method: 'post',
        params:data
    })
}
//发送通知
export function boardSendnotice(data) {
    return request({
        url: 'api/board/sendnotice',
        method: 'post',
        params:data
    })
}
//停止通知
export function boardStopnotice(data) {
    return request({
        url: 'api/board/stopnotice',
        method: 'post',
        params:data
    })
}

//评价
export function engineFeedback(data) {
    return request({
        url: '/api/engine/feedback',
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        data
    })
}

//小红书
export function getredbook(data) {
    return request({
        url: '/api/redbook/getquery',
        method: 'get',
        // 点击刷新不加载
        headers:{
            showLoading:false  

        },
    })
}
// 营销文案-SEO：seomarketing
export function getseomarketing(data) {
    return request({
        url: '/api/seomarketing/getquery',
        method: 'get',
        // 点击刷新不加载
        headers:{
            showLoading:false  

        },
    })
}


// 新闻稿：foreignnews
export function getforeignnews(data) {
    return request({
        url: '/api/foreignnews/getquery',
        method: 'get',
        // 点击刷新不加载
        headers:{
            showLoading:false  

        },
    })
}
// 代码Debug：codedebug
export function getcodedebug(data) {
    return request({
        url: '/api/codedebug/getquery',
        method: 'get',
        // 点击刷新不加载
        headers:{
            showLoading:false  

        },
    })
}

// 列计划：makeplan
export function getmakeplan(data) {
    return request({
        url: '/api/makeplan/getquery',
        method: 'get',
        // 点击刷新不加载
        headers:{
            showLoading:false  

        },
    })
}
// 抖音视频文案：douyin
export function getdouyin(data) {
    return request({
        url: '/api/douyin/getquery',
        method: 'get',
        // 点击刷新不加载
        headers:{
            showLoading:false  

        },
    })
}
//稿件润色
export function getpolish(data) {
    return request({
        url: '/api/polish/getquery',
        method: 'get',
        // 点击刷新不加载
        headers:{
            showLoading:false  

        },
    })
}
//算命
export function getdivine(data) {
    return request({
        url: '/api/divine/getquery',
        method: 'get',
        // 点击刷新不加载
        headers:{
            showLoading:false  

        },
    })
}

//保存对话
export function engineSavedialog(data) {
    return request({
        url: 'api/engine/savedialog',
        method: 'get',
        // 点击刷新不加载
        headers:{
            showLoading:false  

        },
        params:data
    })
}
//历史对话列表
export function engineGetdialoglist(data) {
    return request({
        url: 'api/engine/getdialoglist',
        method: 'get',
        // 点击刷新不加载
        headers:{
            showLoading:false  

        },
        params:data
    })
}
//详细对话流
export function engineGetdialog(data) {
    return request({
        url: 'api/engine/getdialog',
        method: 'get',
        // 点击刷新不加载
        headers:{
            showLoading:false  

        },
        params:data
    })
}
// 删除对话
export function engineDeldialog(data) {
    return request({
        url: '/api/engine/deldialog',
        method: 'get',
        // 点击刷新不加载
        headers:{
            showLoading:false  

        },
        params:data
    })
}
