import axios from 'axios'
import { MessageBox, Message } from 'element-ui'
import { showScreenLoading, hideScreenLoading } from "./loading";
import router from '@/router'
import store from '@/store'
import { getTokenA, getTokenB, removeToken } from '@/utils/auth'
import Cookies from 'js-cookie'

// create an axios instance
const service = axios.create({
    // baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
    // withCredentials: true, // send cookies when cross-domain requests
    timeout: 15000 // request timeout
})

// request interceptor
service.interceptors.request.use(
    config => {
        if (getTokenA()) {
            Cookies.set('oa_skey', getTokenA(),{ expires: 365 })
            Cookies.set('oa_user_id', getTokenB(),{ expires: 365 })
        }
        if (config.headers.showLoading !== false) {
            showScreenLoading(config.headers);
        }
        return config
    },
    error => {
        // do something with request error
        if (config.headers.showLoading !== false) {
            hideScreenLoading(config.headers);
        }
        console.log(error) // for debug
        return Promise.reject(error)
    }
)

// response interceptor
service.interceptors.response.use(
    /**
     * If you want to get http information such as headers or status
     * Please return  response => response
    */

    /**
     * Determine the request status by custom code
     * Here is just an example
     * You can also judge the status by HTTP Status Code
     */
    //通用处理返回结果
    response => {
        const res = response.data
        setTimeout(() => {
            if (response.config.headers.showLoading !== false) {
                hideScreenLoading();
            }
        }, 200);
        // console.log(res)
        // if the custom code is not 20000, it is judged as an error.
        if (res.code != 0) {
            // if(res.code==undefined){
            //     return JSON.parse(res);
            // }
            Message({
                message: res.result.message || 'Error',
                type: 'error',
                
                duration: 5 * 1000
            })
        }else{
            return res
        }

    },
    error => {
        if (error.response.config.headers.showLoading !== false) {
            hideScreenLoading();
        }
        if(error.response.status==401){
            router.push('/login')
            // Message({
            //     message: "登录超时",
            //     type: 'error',
            //     duration: 5 * 1000
            // })
            return false;
        }else {
            // console.log('err' + error.response.data.detail) // for debug
            Message({
                message: error.response.data.detail,
                type: 'error',
                duration: 5 * 1000
            })
            return Promise.reject(error)
        }
    }
)

export default service
