import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export const constantRoutes = [
    {
        path: "/",
        redirect: "/index",
        hidden: 0
    },
    {
        id: 0,
        path: '/login',
        name: "登录",
        component: () => import('@/views/login/login.vue'),
    },
    {
        id: 1,
        path: '/head',
        name: "",
        component: () => import('@/views/head/index.vue'),
        children: [{
            id: 101,
            path: '/index',
            name: "首页",
            component: () => import('@/views/home/index.vue'),
        },{
            id: 102,
            path: '/my',
            name: "账号中心",
            component: () => import('@/views/my/index.vue'),
        },{
            id: 103,
            path: '/chat',
            name: "会话",
            component: () => import('@/views/chat/index.vue'),
        },{
            id: 104,
            path: '/board',
            name: "管理界面",
            component: () => import('@/views/admin/index.vue'),
        },{
            id: 105,
            path: '/mychat',
            name: "历史对话",
            component: () => import('@/views/mychat/index.vue'),
        },{
            id: 106,
            path: '/appchat',
            name: "app会话",
            component: () => import('@/views/appchat/index.vue'),
        }
    ]
    },
]
const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)

}

export const asyncRoutes = [
    // 404 page must be placed at the end !!!
    { path: '*', redirect: '/404', hidden: true }
]

const createRouter = () => new Router({
    mode: 'history', // require service support  这是干嘛用的下次查
    scrollBehavior: () => ({ y: 0 }),
    routes: constantRoutes
})
const router = createRouter()

export function resetRouter() {
    const newRouter = createRouter()
    router.matcher = newRouter.matcher // reset router
}


export default router;
